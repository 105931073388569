import React, { useState, useEffect } from "react";
import axios from "axios";

function Review() {
  // State variables to store review data
  const [reviews, setReviews] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    comment: "",
  });

  const getToken = () => {
    return localStorage.getItem("token");
  };

  useEffect(() => {
    // Fetch all reviews when component mounts
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/review/getall`
      );
      // Check if response status is success and contacts array exists
      if (
        response.data.status === "success" &&
        Array.isArray(response.data.contacts)
      ) {
        setReviews(response.data.contacts); // Set reviews state with the contacts array
      } else {
        console.log("Error: Invalid response format or no contacts found");
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/review/send-review`,
        formData,
        { withCredentials: true, headers: { Authorization: `Bearer ${token}` } }
      );
      fetchReviews(); // Fetch reviews again to update the list with the new one
      console.log("the post data");
      setFormData({ name: "", email: "", comment: "" }); // Clear form fields
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };
  const currentDate = new Date().toLocaleDateString();

  return (
    
    <div className="  " style={{ fontFamily: "Poppins" }}>
      <form onSubmit={handleSubmit}>
        <div className="w-[80%] mx-auto  my-10 text-left">
          <h1
            className=" xl:text-[18px] text-[14px] md:text-[17px] font-semibold py-3 bg-[#F0AF73] text-left mb-4"
            style={{ fontFamily: "Poppins" }}
          >
            Share Your Experience: Your Feedback Matters to Us! Leave a Review
            and Help Us Improve Our Products and Services for You and Others in
            Our Community.
          </h1>
          <h1
            className=" text-justify xl:text-[15px] text-[13px] xl:leading-7 leading-5"
            style={{ fontFamily: "Poppins" }}
          >
            Welcome to our Review Page! Your feedback matters to us, and we
            greatly appreciate you taking the time to share your thoughts. Here,
            you can leave a review of your experience with our products or
            services. Your reviews help us understand what we're doing well and
            where we can improve, ensuring that we continue to provide the best
            possible experience for our customers. Thank you for your support
            and for being a valued part of our community. We look forward to
            hearing from you!
          </h1>
          <div className="flex flex-col mt-6  text-[14px] xl:text-[20px]">
            <div className="flex gap-2 ">
              Comment
              <p className="text-red-700 font-bold">*</p>
            </div>
            <textarea
              className="border-2 border-gray-400 lg:hidden rounded-md p-2 mt-1 xl:w-[1020px] xl:h-[350px] lg:w-[980px] lg:h-[350px] relative "
              rows="3"
              placeholder="1500 Characters Max"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
            ></textarea>
            <textarea
              className=" border-2 border-gray-400 md:hidden sm:hidden hidden lg:block rounded-md p-2 mt-1 xl:w-[1020px] xl:h-[350px] lg:w-[870px] lg:h-[350px]  "
              rows="3"
              placeholder=""
              name="comment"
              value={formData.comment}
              onChange={handleChange}
            ></textarea>

            <span className="absolute  h-fit w-fit  xl:mt-[340px] xl:ml-[800px] lg:mt-[340px] lg:ml-[700px] md:hidden sm:hidden hidden lg:block xl:block text-gray-400 font-medium ">
              1500 Characters Max
            </span>
          </div>
          
          <div className="flex mt-4  ">
            <p className="text-red-700 mr-2 font-bold ">*</p>
            <div className="flex flex-col gap-3">
              <label className="font-bold">Your Email</label>
              <input
                type="text"
                name="email"
                id=""
                value={formData.email}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] lg:w-[591px] lg:h-[30px] sm:w-[400px] sm:h-[30px] outline-2 border-2 border-black rounded-sm mb-4"
              />
            </div>
          </div>
          <div className="flex ">
            <p className="text-red-700 mr-2 font-bold">*</p>
            <div className="flex flex-col gap-3">
              <label className="font-bold">Your Name</label>
              <input
                type="text"
                name="name"
                id=""
                value={formData.name}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px]  lg:w-[591px] lg:h-[30px] sm:w-[400px] sm:h-[30px] outline-2 border-2 border-black rounded-sm"
              />
            </div>
          </div>
          <button className="bg-[#F58634] rounded-lg hover:bg-orange-600  w-[220px] text-xl h-[40px] mt-3 text-white font-semibold mb-7">
            Post Comment
          </button>
          <p className="ml-5 mt-4">
            Note: Your name and email will be displayed here
          </p>
          <h1 className="bg-[#F58634] p-2 rounded-lg mt-6 text-black font-normal">
            Recent Reviews
          </h1>
          {/* latter */}
          <div className="border-b-2 pb-5 pt-5">
            <p className="font-semibold">Amit Singh</p>
            <p className="font-semibold mb-4">Posted on 04-04-2024</p>
            <p className="text-justify leading-7">
              Working with Doledge India for my resume selling services has been
              an absolute game-changer for my career. The team's professionalism
              and attention to detail are unmatched. They took the time to
              understand my unique skills and experiences, crafting a resume
              that truly highlights my strengths. Thanks to their expertise, I
              landed my dream job within weeks of updating my resume. I highly
              recommend Doledge India to anyone looking to take their career to
              the next level!"
            </p>
          </div>
          <div className="border-b-2 pb-5 pt-5">
            <p className="font-semibold">Rajesh Sharma</p>
            <p className="font-semibold mb-4">Posted on 02-04-2024</p>
            <p className="text-justify leading-7">
              My experience with Doledge India's resume selling services has
              been nothing short of exceptional. From the initial consultation
              to the final delivery, their team demonstrated professionalism,
              expertise, and a genuine commitment to my success. They took the
              time to understand my career goals and tailored my resume
              accordingly, ensuring that it effectively showcased my skills and
              experiences. The result exceeded my expectations, and I received
              multiple job offers shortly after updating my resume. I am
              incredibly grateful to the Doledge India team for their
              outstanding work and would highly recommend their services to
              anyone looking to advance their career.
            </p>
          </div>
          <div className="border-b-2 pb-5 pt-5">
            <p className="font-semibold">Amit Singh</p>
            <p className="font-semibold mb-4">Posted on 04-04-2024</p>
            <p className="text-justify leading-7">
              Working with Doledge India for my resume selling services has been
              an absolute game-changer for my career. The team's professionalism
              and attention to detail are unmatched. They took the time to
              understand my unique skills and experiences, crafting a resume
              that truly highlights my strengths. Thanks to their expertise, I
              landed my dream job within weeks of updating my resume. I highly
              recommend Doledge India to anyone looking to take their career to
              the next level!"
            </p>
          </div>
          <div className="border-b-2 pb-5 pt-5">
            <p className="font-semibold">Rajesh Sharma</p>
            <p className="font-semibold mb-4">Posted on 04-03-2024</p>
            <p className="text-justify leading-7">
              My experience with Doledge India's resume selling services has
              been nothing short of exceptional. From the initial consultation
              to the final delivery, their team demonstrated professionalism,
              expertise, and a genuine commitment to my success. They took the
              time to understand my career goals and tailored my resume
              accordingly, ensuring that it effectively showcased my skills and
              experiences. The result exceeded my expectations, and I received
              multiple job offers shortly after updating my resume. I am
              incredibly grateful to the Doledge India team for their
              outstanding work and would highly recommend their services to
              anyone looking to advance their career.
            </p>
          </div>
          {reviews.map((review, index) => (
            <div key={index} className="border-b-2 pb-5 pt-5">
              <p className="font-semibold">{review.name}</p>
              <p className="font-semibold mb-4">Posted on {currentDate}</p>
              <p className="text-justify leading-7">{review.comment}</p>
            </div>
          ))}
        </div>
      </form>
    </div>
  );
}

export default Review;
