import React, { useState } from 'react';
import "../css/Callservice.css";
import axios from 'axios';
import "@fontsource/poppins"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faHeadset, faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-hot-toast";
import emailjs from 'emailjs-com';


function Callme() {

  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    Query: "",
  });

  const { name, email, phoneNumber, Query } = user;

  const handleOnChange = (e) => {
    if (
      e.target.type === "number" &&
      e.target.value.length > e.target.maxLength
    ) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
    setUser((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const [showCallback, setShowCallback] = useState(false);
  const handleCallbackClick = () => {
    setShowCallback(!showCallback);
  };

  const submitContactFormData = (e) => {
    e.preventDefault();
  
    // Your email service details from emailjs.com
    const serviceId = 'service_y9f31gr';
    const templateId = 'template_kucnkfj';
    const userId = 'qkr122zBJLIxNCizw';
  
    // Sending the email
    emailjs.send(serviceId, templateId, user, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        toast.success("Email sent successfully");
        // Reset the form fields
        setUser({
          name: "",
          email: "",
          phoneNumber: "",
          Query: ""
        });
      }, (error) => {
        console.error('Email sending failed:', error);
        toast.error("Email sending failed");
      });
  };
  

  return (
    <div className="widgetContnr mt-14" id="rightWidget" >
      <ul className="contactWidget fadeInRight">
        <li className="nopointr">
          <FontAwesomeIcon className="tollIc" icon={faHeadset} />
          <div className="cal">
            <p className="txtBlue"> TALK TO US</p>
            <p className="txtRed font_16">+91 9311679499</p>
            <p className="font_normal" style={{ "paddingRight": "13px" }}>Toll Free</p>
          </div>
        </li>
        <li id="calBack">
          <div className="widgtLi_2">
            <a id="callMeBack" onClick={handleCallbackClick} style={{ color: "#004276" }}>
              <FontAwesomeIcon style={{ "paddingRight": "6px" }} icon={faPhoneVolume} /> CALL ME BACK
            </a>
          </div>
        </li>
      </ul>

      {showCallback &&
        <div className="calBackCont displayB zeroHt" id="calBackForm">
          <div className="calMeForm" id="temp2">
            <div className="bgGreen p0 oh">
              <a className="closeForm" id="closeForm" onClick={handleCallbackClick}>
                <em> <FontAwesomeIcon icon={faXmark} style={{ "height": "1.8em", "color": "#6f5244" }} /></em>
              </a>
              <p className="formHd">
                Confirm your call request <br />
                <span className="dspB">The information will only be used to reach out to you for Doledge related services.</span>
              </p>
            </div>
            <div className="formInputC" id="temp1">
              <p className="mandtory font_small">All fields are mandatory</p>
              <form name="oldContactUs" id="contactWidget" onSubmit={submitContactFormData}>
                <div className="row">
                  <div className="txtWrap">
                    <input
                      type="text"
                      className="valid"
                      id="name"
                      name="name"
                      placeholder="Name"
                      required
                      maxLength="100"
                      value={name}
                      onChange={handleOnChange}
                    /><em></em>
                  </div>
                  <small className="erLbl" id="name_err"></small>
                </div>
                <div className="row">
                  <div className="txtWrap">
                    <input
                      type="text"
                      className="valid"
                      id="email"
                      name="email"
                      required
                      placeholder="Email"
                      maxLength="100"
                      value={email}
                      onChange={handleOnChange}
                    /><em></em>
                  </div>
                  <small className="erLbl" id="email_err"></small>
                </div>
                <div className="row">
                  <div className="txtWrap">
                    <input
                      type="text"
                      className="valid"
                      id="mobile"
                      name="phoneNumber"
                      placeholder="Mobile"
                      minLength="10"
                      maxLength="15"
                      value={phoneNumber}
                      required
                      onChange={handleOnChange}
                    /><em></em>
                  </div>
                  <small className="erLbl" id="mobile_err"></small>
                </div>
                <div className="row pb15">
                  <div className="txtWrap txtarea">
                    <textarea
                      placeholder="Type your query here.."
                      className="valid"
                      id="message"
                      name="Query"
                      maxLength="600"
                      rows="3"
                      value={Query}
                      onChange={handleOnChange}
                      required
                    ></textarea><em></em>
                  </div>
                  <small className="erLbl" id="message_err"></small>
                </div>
                <div>
                  <input type="hidden" name="SOURCE_ID" value="2" />
                  <input
                    type="submit"
                    className="blue_btnBig"
                    style={{ backgroundColor: '#F58634' }}
                    id="submitQuery"
                    value="Call Me Back"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Callme;
