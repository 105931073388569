import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams hook
import contextAuth from "../ContextAPI/ContextAuth"; // Import the context
import Image4 from "../images/JobSearch4.png";
import bgImage from "../images/bgJobSearch.png";

const SingleBlogPage = () => {
  const [blog, setBlog] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();
  const { blogId } = useParams();
  const { token, loading, setLoading } = useContext(contextAuth);

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_PRO_URL}/api/blogs/${blogId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log("this is token ----->", token);

        console.log(response.data);
        setBlog(response.data);
        setTitle(response.data.blog.title);
        setDescription(response.data.blog.description);
        setImage(response.data.blog.image);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
      setLoading(false);
    };

    fetchBlog();

    // const handleScroll = () => {
    //   // Show the scroll-to-top button when the user scrolls down
    //   setShowScrollButton(window.scrollY > 100);
    // };

    // Add event listener for scroll
    // window.addEventListener("scroll", handleScroll);

    // Clean up function
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [blogId, token]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  // Custom shadow style
  const customShadow = {
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)"
  };

  // if (!blog) {
  //   return <div>Loading...</div>;
  // }

  return (
    // <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 md:pt-4 md:pb-8">
    //   <div className="max-w-[1000px] p-4 mx-auto bg-white rounded-lg shadow-md">
    //     <h1 className="text-2xl font-bold md:text-3xl">{title}</h1>
    //     <div className="p-4 flex flex-col gap-4">
    //       <img className="w-full mx-auto h-64 object-cover rounded-lg md:h-96" src={image} alt={"blog image"} />
    //       <p className="text-gray-600 mt-2 md:text-lg text-left" style={{ fontFamily: 'sans-serif' }} dangerouslySetInnerHTML={{ __html: description }}></p>
    //     </div>
    //   </div>
    // </div>


    <div className="flex flex-col items-center justify-center min-h-screen md:pt-4 md:pb-8 mb-20">
      <img className="w-full max-w-[1300px] mx-auto h-64 object-cover rounded-lg md:h-96 border mt-2" src={image} alt={"blog image"} />
      <div className="max-w-[1000px] p-4 mx-auto">
        <div className="p-4 flex flex-col gap-4">
          <h1 className="text-2xl font-bold md:text-3xl lg:leading-[45px]">{title}</h1>
          <hr className="w-[70%] mx-auto" />
          <p className="mt-2 md:text-lg text-justify sm:text-left tracking-wide" style={{ fontFamily: 'poppins' }} dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
      </div>
    </div>
  );
};

export default SingleBlogPage;