import React, { useEffect, useState, useContext } from "react";
import { Box, Button, useTheme } from "@mui/material";
import toast from "react-hot-toast";
import contextAuth from "../../../ContextAPI/ContextAuth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Aarrow from '../../../images/image 205.png';
import Blog from "../../Blog";
import Contactus from "../../Contactus";

const PersonalPortfolio = () => {
  const [planprice, setPlanprice] = useState();
  const { setLoading, token } = useContext(contextAuth);
  const [serviceType, setServiceType] = useState();
  const [planId, setPlanId] = useState();
  const [serviceId, setServiceId] = useState();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchDataResume = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_PRO_URL}/api/PersonalPortfolioService/plans`
        );
        console.log(response);

        const { status, price } = response.data;

        if (status === "success") {
          const showSelectedService = price[0];
          console.log(showSelectedService);
          setPlanId(showSelectedService._id);
          setServiceId(showSelectedService._id);
          setServiceType(showSelectedService.serviceName);
          setPlanprice(showSelectedService.price);
        } else {
          throw new Error("Failed to fetch  interview Prep service ");
        }
      } catch (error) {
        toast.error("Error fetching  Interview prep service ");
      } finally {
        setLoading(false);
      }
    };
    fetchDataResume();
  }, []);

  const handleBuy = async (e) => {
    e.preventDefault();
    let cartId = null;
    if (localStorage.getItem("cartId") && !token) {
      cartId = JSON.parse(localStorage.getItem("cartId"));
    }

    setLoading(true);

    try {
      // Adding Items to the Cart
      const data = {
        serviceType,
        service: {
          id: serviceId,
          name: serviceType,
        },
        plans: [
          {
            id: planId,
            price: planprice,
          },
        ],
        cartId,
      };
      const addToCartRes = await axios.post(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/cart/${token ? "addtocart" : "add-to-cart"
        }`,
        data,
        { withCredentials: true, headers: { Authorization: `Bearer ${token}` } }
      );

      const responseCartData = addToCartRes.data;
      if (!responseCartData) {
        throw new Error("Error occurred while adding to Cart");
      } else {
        console.log("Server Response (Add to Cart):", responseCartData);
        toast.success("Package added to cart successfully");
        if (!localStorage.getItem("cartId") && !token) {
          localStorage.setItem(
            "cartId",
            JSON.stringify(responseCartData?.cart?._id)
          );
        }
        navigate("/cart");
      }
    } catch (error) {
      console.error("Error occurred during API requests:", error);
      toast.error(error.message || "Error occurred during API requests");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full h-auto mx-auto pt-2" style={{ fontFamily: "inter" }}>
      {/* Section 1 */}
      <div style={{ fontFamily: "inter" }} className="bg-white">
        <div
          style={{ fontFamily: "inter" }}
          className="lg:flex flex-col h-full w-full lg:flex-row max-w-[2000px] mx-auto"
        >
          <div className=" w-full lg:w-[45%]  justify-center items-center h-[100px] lg:h-auto max-lg:h-[300px]">
            <img
              className="h-full w-full object-fill"
              src={require("../../../images/CombosHighlights/image 203.png")}
              alt=""
            />
          </div>
          <div className="bg-[#F58634] flex flex-col items-start justify-center h-auto lg:w-[55%] w-full p-4 gap-y-2">
            <h1 className="text-[15px] lg:text-lg font-light !leading-[37px] lg:my-10 mt-3 text-white  w-[100%] text-justify">
              Craft Your Unique Identity: Elevate Your Brand with Our Personal
              Portfolio Services. Unlock Your Potential and Stand Out in Your
              Field with a Tailored Showcase of Your Skills, Achievements, and
              Experience. Let Your Portfolio Speak Volumes About You and Your
              Professional Journey
            </h1>

            <h2 className="text-[15px] lg:text-lg  !leading-[30px] lg:my-10 mt-2 text-black font-medium text-left w-[100%]">
              Showcase Your Best: Personal Portfolio Services Tailored for You
            </h2>
          </div>
        </div>
      </div>
      {/*section 2*/}
      <div className="flex flex-col xl:flex-row justify-center items-center xl:justify-between xl:items-stretch gap-x-8 mt-5 w-[90%] xl:w-[80%] mx-auto max-w-[1500px]">
        <div className="flex flex-col justify-center items-start  xl:w-[70%]">
          <div
            className="text-black text-base md:text-xl max-w-screen-sm md:max-w-full lg:max-w-2xl py-2 rounded-lg self-start text-center font-semibold mb-2 mx-auto"
            style={{
              fontFamily: "Poppins",
              fontWeight: 300,
              textAlign: "center",
            }}
          >
            Why do you need Doledge Personal Portfolio Services?

          </div>

          <table className="table table-striped table-bordered table-auto">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="text-center !bg-[#4472c4] !text-white text-lg pb-3"
                >
                  Benefits
                </th>
                <th
                  scope="col"
                  className="text-center !bg-[#4472c4] !text-white"
                >
                  Without Service
                </th>
                <th
                  scope="col"
                  className="text-center !bg-[#4472c4] !text-white"
                >
                  With Service
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="benefit text-left sm:px-5 text-[13px] font-medium">
                  Increase your online presence
                </td>
                <td className="text-center sm:px-5 benefitGiven">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red" }}
                    size="xl"
                  />
                </td>
                <td className="text-center sm:px-5 benefitGiven">
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="xl"
                  />
                </td>
              </tr>
              <tr className="">
                <td className="text-left sm:px-5 text-[13px] font-medium">Showcase your skills, achievements, and projects in a visually appealing and organized manner

                </td>
                <td className="text-center sm:px-5">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red" }}
                    size="xl"
                  />
                </td>
                <td className="text-center sm:px-5">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="xl"
                  />
                </td>
              </tr>
              <tr className="">
                <td className="text-left sm:px-5 text-[13px] font-medium">A well-crafted portfolio can help you secure job interviews, freelance projects, or business opportunities.
                </td>
                <td className="text-center sm:px-5 benefitGiven">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red" }}
                    size="xl"
                  />
                </td>
                <td className="text-center sm:px-5">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="xl"
                  />
                </td>
              </tr>

              <tr className="">
                <td className="text-left sm:px-5 text-[13px] font-medium">Helping you establish credibility and authority in your field.
                </td>
                <td className="text-center sm:px-5">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red" }}
                    size="xl"
                  />
                </td>
                <td className="text-center sm:px-5 benefitGiven">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="xl"
                  />
                </td>
              </tr>

              <tr className="">
                <td className="text-left sm:px-5 text-[13px] font-medium">With digital portfolios, you can easily customize and adapt your content to target specific audiences or opportunities.
                </td>
                <td className="text-center sm:px-5">
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red" }}
                    size="xl"
                  />
                </td>
                <td className="text-center sm:px-5">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="xl"
                  />
                </td>
              </tr>

              <tr className="">
                <td className="text-left sm:px-5 text-[13px] font-medium">Professional portfolio services often include tools and features for easy management, organization, and sharing of your work.
                </td>
                <td className="text-center sm:px-5">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red" }}
                    size="xl"
                  />
                </td>
                <td className="text-center sm:px-5 benefitGiven">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="xl"
                  />
                </td>
              </tr>

              <tr className="">
                <td className="text-left sm:px-5 text-[13px] font-medium">
                  Faster response times and personalized assistance with any technical or design issues.
                </td>
                <td className="text-center sm:px-5">
                  {" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red" }}
                    size="xl"
                  />
                </td>
                <td className="text-center sm:px-5 benefitGiven">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                    size="xl"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-[70%] sm:w-[55%] mx-auto  my-auto lg:w-[30%] flex flex-col gap-y-5 lg:gap-y-1">
          <h1 className=" text-base md:text-xl lg:text-[20px] font-medium text-center">
            Personal Portfolio
          </h1>

          <form className="flex flex-col gap-2 pb-3 text-center justify-evenly text-base sm:text-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 md:py-5 rounded-xl lg:rounded-none">
            <div className="h-2"></div>
            <div className="mt-3 mb-3 p-4 bg-[#D9D9D9] ">
              <p
                className="lg:text-3xl md:text-xl font-medium  lg:text-[26px] text-center text-[#444]"
                style={{ fontFamily: "Arial" }}
              >
                {" "}
                Rs. {planprice}/-
              </p>
              <p className="text-xs">*Applicable Taxes may apply</p>
            </div>
            <Box sx={{ textAlign: "center", padding: "10px" }}>
              <Button
                type="submit"
                onClick={handleBuy}
                variant="contained"
                style={{ backgroundColor: "rgba(245, 134, 52, 1)", fontSize: '17px' }}
              >
                Buy Now
              </Button>
            </Box>
          </form>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center  gap-y-6 w-[95%] md:w-[85%] lg:w-[70%] max-w-[1500px] mx-auto mt-5">
        <div className="bg-[#62B01E] mx-auto md:h-auto rounded-xl text-[15px] md:text-[17px] lg:text-[20px] flex justify-center items-center lg:px-10 md:px-8 xl:px-8 lg:py-2 py-6 md:py-4 max-md:px-6 font-medium ">
          Empower Your Professional Journey: Your Story, Your Portfolio, Your
          Success
        </div>
        <div className="flex flex-col lg:flex-row justify-evenly items-center max-w-[1200px]">
          <p className="flex flex-col gap-y-5 lg:w-[60%] text-justify px-2 lg:mb-8 text-[13px] lg:text-base !leading-8">
            In today's digital age, having an online portfolio increases an
            individual's visibility and discoverability on the internet. It
            allows them to showcase their work to a global audience and reach
            potential opportunities that may not be accessible through
            traditional channels.Portfolios contribute to personal branding
            efforts by presenting a cohesive and professional image of an
            individual's work and identity. They help establish credibility,
            authority, and trust within their industry or field of expertise.
            Portfolios serve as powerful tools for career advancement by
            showcasing past accomplishments, demonstrating skills and
            competencies, and illustrating growth and progress over time. They
            can help individuals secure job opportunities, freelance projects,
            collaborations, or speaking engagements.
          </p>
          <div className=" lg:w-[42%]">
            <img
              className="lg:w-[417px] lg:h-[391px] object-contain mx-auto lg:py-8 lg:px-12 px-4"
              src={require("../../../images/image 212.png")}
              alt=""
              loading="lazy"
            />

          </div>
        </div>
      </div>

      <div className=" font-light" style={{ fontFamily: "poppins" }}>
        <div className="w-full h-auto  bg-[#F0AF73] box-border">
          <h1 className="text-[#000000] text-center text-lg lg:text-xl leading-[40px] font-normal px-3 py-3">
            Unlocking Opportunities: The Benefits of Personal Portfolio Services
          </h1>
        </div>

        <div className="flex lg:flex-row md:flex-row flex-col lg:w-[70%] max-w-[1100px] mx-auto mt-6 mb-12">
          <div className="flex flex-col mx-auto ">
            {/* 1. */}
            <div className="flex   mb-2 w-fit items-center">
              <img src={Aarrow} alt="" className="lg:h-12 lg:w-12 h-8 w-8" />
              <h2 className="lg:text-lg text-base  text-left">Showcasing Skills and Achievements</h2>
            </div>
            {/* 2. */}
            <div className="flex  mb-2 w-fit items-center">
              <img src={Aarrow} alt="" className="lg:h-12 lg:w-12 h-8 w-8" />
              <h2 className="lg:text-lg text-base  text-left">Professional Branding</h2>
            </div>
            {/* 3. */}
            <div className="flex  mb-2 w-fit items-center">
              <img src={Aarrow} alt="" className="lg:h-12 lg:w-12 h-8 w-8" />
              <h2 className="lg:text-lg text-base  text-left">Career Advancement</h2>
            </div>
            {/* 4. */}
            <div className="flex  mb-2 w-fit items-center">
              <img src={Aarrow} alt="" className="lg:h-12 lg:w-12 h-8 w-8" />
              <h2 className="lg:text-lg text-base  text-left">
                Networking
              </h2>
            </div>
            {/* 5. */}
            <div className="flex  mb-2 w-fit items-center">
              <img src={Aarrow} alt="" className="lg:h-12 lg:w-12 h-8 w-8" />
              <h2 className="lg:text-lg text-base  text-left">Continuous Improvement</h2>
            </div>
            {/* 6. */}
            <div className="flex   mb-2 items-center">
              <img src={Aarrow} alt="" className="lg:h-12 lg:w-12 h-8 w-8" />
              <h2 className="lg:text-lg text-base  text-left">Online Visibility</h2>
            </div>

          </div>
          <div className=" h-auto mx-auto my-auto">
            <img
              className="lg:w-[400px] lg:h-[330px] md:h-[270px]"
              src={require("./../../../images/image 211.png")}
              alt=" Background Verification"
            />
          </div>

        </div>
      </div>

      <Contactus />

      <Blog />

    </div>
  );
};

export default PersonalPortfolio;
