import React, { useEffect, useRef, useState, useContext } from "react";
import Login from "./Login"; // Import your Login component;
import { toast } from "react-hot-toast";
import Spinner from "../UI/Spinner/Spinner";
import contextAuth from "../ContextAPI/ContextAuth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TermsAndConditionsPopup from "./PaymentScreen/ScreeningServices/TermsAndConditionsPopup";

const LoginModal = ({ closeModal }) => {
    const modalRef = useRef(null);
    const [showRegisterPopup, setShowRegisterPopup] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const Auth = useContext(contextAuth);

    const navigate = useNavigate();
    // Close modal when clicking outside of it
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    // Register form submission
    const submitFormHandler = (e) => {
        e.preventDefault();

        if (name.length < 5) {
            toast.error("Name should be min 5 characters.");
            return;
        }

        if (password.length < 8 || password.length > 20) {
            toast.error("Password must be 8 to 20 characters long.");
            return;
        }

        if (tel.length !== 10) {
            toast.error("Mobile number must be of 10 digits.");
            return;
        }

        // Check if password and confirm password match
        if (password !== confirmPassword) {
            toast.error("Password and Confirm Password didn't Match")
            return;
        }

        if (!tnc) {
            toast.error("Please agree to terms & conditions");
            return;
        }


        let data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("password", password);
        data.append("tel", tel);
        data.append("cartid", JSON.parse(localStorage.getItem("cartId")));
        Auth.loadingHandler(true);

        axios
            .post(`${process.env.REACT_APP_SERVER_PRO_URL}/api/auth/signup`, data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                })
            .then((response) => {
                const data = response.data;
                if (data.status == "success") {
                    Auth.login(data.user, data.token);
                    Auth.getCartItems(data.token);
                    Auth.loadingHandler(false);
                    setShowRegisterPopup(false);
                    closeModal();
                    toast.success("Signup Successful");
                    const intendedDestination = localStorage.getItem(
                        "intendedDestination"
                    );
                    if (intendedDestination) {
                        navigate(intendedDestination);
                        localStorage.removeItem("intendedDestination");
                    } else {
                        navigate("/login");
                    }
                } else {
                    Auth.loadingHandler(false);
                    Auth.errorHandler({ message: data.error, type: data.type });
                }
            })
            .catch((err) => {
                navigate("/register");
                Auth.errorHandler({
                    message: "Internal server error occured",
                    type: "",
                });
                Auth.loadingHandler(false);
                console.log(err);
                toast.error("Signup Failed")
            });

    };

    // Function to handle registration button click
    const handleRegisterButtonClick = () => {
        setShowRegisterPopup(true);
    };

    //termS and condiotion

    const [showTermsPopup, setShowTermsPopup] = useState(false);
    const [tnc, setTnc] = useState(false);

    const handleAgreeToTerms = (agree) => {
        setShowTermsPopup(false);
        if (agree) {
            setTnc(true);
        }
    };

    const handleCheckboxChange = () => {
        if (!tnc) {
            setShowTermsPopup(true);
        } else {
            setTnc(false);
        }
    };

    const handleShowTermsPopup = () => {
        setShowTermsPopup(true);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={handleClickOutside}>
            <div ref={modalRef} className="bg-white rounded-lg shadow-2xl relative " onClick={(e) => e.stopPropagation()}>
                {/* Close button */}
                <button className="absolute top-0 right-0 m-4 text-red-600 hover:text-red-700 focus:outline-none" onClick={closeModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                {/* Login component */}
                <Login closeModal={closeModal} />

                {/* Register button */}
                <div className=" absolute submitWrapper flex flex-col text-center mt-2 left-[33%]  sm:left-[28%] bottom-[27.5%] sm:bottom-[28.8%] md:bottom-[13%] md:left-[37%]">
                    <button
                        className="submit mt-2 p-2 px-4  text-white font-semibold rounded-full"
                        style={{ backgroundColor: "#006700" }}
                        onClick={handleRegisterButtonClick}
                    >
                        Register Now
                    </button>
                </div>

                {/* Register popup */}
                {showRegisterPopup && (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-2xl p-7 w-[29rem]">
                            <div className="flex justify-end">
                                <button className="text-red-600 hover:text-red-700 focus:outline-none" onClick={() => setShowRegisterPopup(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <h2 className="text-xl font-semibold mb-4">Register For Free</h2>
                            <form onSubmit={submitFormHandler}>
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-sm  text-left font-medium text-gray-700">Name</label>
                                    <input type="text" required placeholder="Enter your Name" error={"Name is too short minLength of 5 characters"} id="name" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm text-left font-medium text-gray-700">Email</label>
                                    <input type="email" required placeholder="Enter your Email ID" error={"Please enter a valid Email Address"} id="email" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="tel" className="block text-sm text-left font-medium text-gray-700">Mobile Number</label>
                                    <input type="tel" required placeholder="Enter your Mobile Number" error={"Please enter a valid telephone number"} id="tel" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" value={tel} onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                        maxLength={10} onChange={(e) => setTel(e.target.value)} />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="block text-sm text-left font-medium text-gray-700">Password</label>
                                    <input type="password" required placeholder="Enter your Password" id="password" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="block text-sm text-left font-medium text-gray-700">Confirm Password</label>
                                    <input type="password" required placeholder="Confirm your Password" id="confirmPassword" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>

                                {/* Terms and Conditions Checkbox */}
                                <div className="mb-4  flex justify-start items-center mt-3 font-medium">
                                    <input
                                        type="checkbox"
                                        id="terms-checkbox"
                                        checked={tnc}
                                        onChange={handleCheckboxChange}
                                        className="mr-3 cursor-pointer w-5 h-5"
                                    // size={300}
                                    />
                                    <label className="text-[11px] text-left" htmlFor="terms-checkbox">
                                        I HAVE READ AND AGREE TO THE WEBSITE{" "}
                                        <a href="#" className="text-blue-500">
                                            TERMS AND CONDITIONS
                                        </a>
                                        <span className="text-red-500">*</span>
                                    </label>
                                </div>
                                {showTermsPopup && (
                                    <TermsAndConditionsPopup
                                        onClose={() => setShowTermsPopup(false)}
                                        onAgree={handleAgreeToTerms}
                                    />
                                )}

                                <div className="text-left w-[100%] flex flex-col">
                                    <p className="gray13px text-xs">
                                        By clicking Register, you agree to the <span className="text-blue-500"> Terms and Conditions</span> & <span className="text-blue-500">Privacy Policy</span> of DoledgeIndia.com
                                    </p>
                                    <button type="submit" className="p-2 px-4 bg-orange-500 text-white font-semibold text-center  hover:bg-orange-600 mt-3 rounded-md">Register & Continue Payment </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginModal;
