import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParam } from "react-router-dom";
import contextAuth from "../ContextAPI/ContextAuth";
import axios from "axios";
import { FaCartPlus } from "react-icons/fa6";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import toast from "react-hot-toast";
import "../css/Dashboard.css";
import LoginModal from "./LoginModal";
import image1 from "../images/image 2.jpg";
import image2 from "../images/image 3.png";
import image3 from "../images/image 4.png";
import image4 from "../images/image 5.png";
import image5 from "../images/image 7.avif";
import image6 from "../images/image 8.png";
import image7 from "../images/image 9.png";
import image8 from "../images/image 10.png";

const Cart = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);

  const { setLoading, token, setCart, user } = useContext(contextAuth);
  //  Fetching CartItems from Backend
  const [cartItem, setcartItem] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [payableAmount, setPayableAmount] = useState();

  const images = [image2, image5, image7, image8, image6, image3, image4, image1];

  // Fetching all services
  const [allService, setAllService] = useState([]);



  // coupon code
  const inputRef = useRef(null);
  const [couponCode, setCouponCode] = useState("");
  const [options, setOptions] = useState(); // coupon code options
  const [showOptions, setShowOptions] = useState(false);
  // for cart id
  const [cartId, setcartId] = useState(null);
  const [cartTotal, setCartTotal] = useState(0);
  const [showcartTotal, setShowCartTotal] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false); // Track if coupon is applied or not
  const [applyVisible, setApplyVisible] = useState(true);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [gst, setGst] = useState(0);
  const [discount, setDiscount] = useState();
  // State to track whether login modal should be open or closed
  const [showLoginModal, setShowLoginModal] = useState(false);

  // Function to open login modal
  const openLoginModal = () => {
    setShowLoginModal(true);
  };

  // Function to close login modal
  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleInputChange = (event) => {
    if (!token) {

      setShowLoginModal(true);
      return;
    }
    const inputText = event.target.value;
    // console.log("Input Text:", inputText);
    setCouponCode(inputText);
    // setShowOptions(inputText.length > 0);
  };

  const handleOptionSelect = (selectedCode) => {
    if (couponCode) {
      toast.error("Remove existing coupon.")
      return;
    }
    setCouponCode(selectedCode);
    // console.log("selectedCode couponCode for applying :", selectedCode)
    setShowOptions(false); // Hide options after selecting
    window.scrollTo(0, 0);
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  // handleClickOutside event listener
  useEffect(() => {
    // Attach click event listener to document
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (token) {
      fetchCouponDetails();
    }
    fetchAllServices();
    // Check if coupon is applied when component mounts
    const storedCoupon = localStorage.getItem("appliedCoupon");
    if (storedCoupon) {
      setIsCouponApplied(true);
      setApplyVisible(false);
      setRemoveVisible(true);
    }
  }, [token]);

  useEffect(() => {
    getCartItems();
  }, [token]);

  // Save applied coupon state in localStorage
  useEffect(() => {
    if (isCouponApplied) {
      localStorage.setItem("appliedCoupon", true);
    } else {
      localStorage.removeItem("appliedCoupon");
    }
  }, [isCouponApplied]);

  // Reset couponCode state when cartItem array becomes empty
  useEffect(() => {
    if (cartItem?.length === 0 && isCouponApplied && discount) {
      removeCoupon();
    }
  }, [cartItem]);

  const getCartItems = async () => {
    let id = null;
    if (localStorage.getItem("cartId") && !token) {
      id = JSON.parse(localStorage.getItem("cartId"));
      // console.log(id);
      setcartId(id);
    }
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/cart/${id ? `cart/${id}` : "my-cart"
        }`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("cart", data);
      setcartItem(data.items);
      setcartId(data._id);
      setCart(data.items.length);
      // const newTotalAmount = data.items.reduce(
      //   (acc, curr) => acc + curr.plans.reduce((ac, cur) => ac + cur.price, 0),
      //   0
      // );
      setTotalAmount(data?.cartTotal);
      setGst(data?.GST);
      setPayableAmount(data?.cartTotalaftergst);
      if (data.appliedCoupon) {
        setIsCouponApplied(true);
        setCouponCode(data.appliedCoupon.couponcode)
        setCartTotal(data.cartTotalaftergst.toFixed(2));
        setDiscount(data.Discount)
        setShowCartTotal(true);
        setApplyVisible(false);
        setRemoveVisible(true);
      } else {
        setIsCouponApplied(false);
        setCouponCode("");
        setDiscount(null);
        setShowCartTotal(false);
        setCartTotal(data.cartTotalaftergst.toFixed(2));
        setApplyVisible(true);
        setRemoveVisible(false);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  // fetch all services for disaplaying frequently bought together
  const fetchAllServices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/home/services`
      );
      if (response.data?.status == "failed") {
        toast.error(response.data?.message);
        throw new Error("ERROR OCCURED IN FETCHING ALL SERVICES......");
      }

      const dummyServices = response.data?.services.map((service, index) => ({
        ...service,
        image: images[index % images.length], // Use modulo to loop through images array
      }));
      setAllService(dummyServices.sort(() => Math.random() - 0.5).slice(0, 3));
      // console.log(allService)
    } catch (error) {
      console.log("ERROR OCCURED IN FETCHING ALL SERVICES......", error);
    }
    setLoading(false);
  };

  //  Deleting Items from the cart
  const DeleteCartItem = (id) => async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_PRO_URL
        }/api/cart/delete-plan-from-cart${!token ? "-open" : ""}/${!token ? `${cartId}/` : ""
        }${id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(response);
      toast.success(response.data.message);
      setcartItem(response.data?.cart?.items);
      setcartId(response.data?.cart?._id);
      setCart(response.data?.cart?.items?.length);
      setTotalAmount(response.data?.cart?.cartTotal);
      setGst(response.data?.cart?.GST);
      setPayableAmount(response.data?.cart?.cartTotalaftergst);
      if (response.data.cart.appliedCoupon) {
        setIsCouponApplied(true);
        setCouponCode(response?.data?.cart.appliedCoupon.couponcode)
        setCartTotal(response?.data?.cart.cartTotalaftergst.toFixed(2));
        setDiscount(response?.data?.cart.Discount)
        setShowCartTotal(true);
        setApplyVisible(false);
        setRemoveVisible(true);
      } else {
        setIsCouponApplied(false);
        setCouponCode("");
        setDiscount(null);
        setShowCartTotal(false);
        setCartTotal(response?.data?.cart.cartTotalaftergst.toFixed(2));
        setApplyVisible(true);
        setRemoveVisible(false);
      }
      //  if want to empty the coupon code after deleting  every item setCouponCode("")
    } catch (error) {
      toast.error("Plan not found in Cart to delete");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //fetching all coupons from the coupons api
  const fetchCouponDetails = async () => {
    setLoading(true);

    try {
      // console.log("This is user's token", token);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/coupans`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const { success, coupan } = response.data;
      // console.log("coupon ka response aaaya re", coupan);
      if (success) {
        setOptions(coupan);
      }
    } catch (error) {
      if (error.response) {
        //! The request was made and the server responded with a status code
        console.log("Server Error:", error.response.data);
        console.log("Status Code:", error.response.status);
      } else if (error.request) {
        //! The request was made but no response was received
        console.log("No response received:", error.request);
      } else {
        //! Something happened in setting up the request that triggered an Error
        console.log("Error:", error.message);
      }
      toast.error("Failed to fetch Coupon Details");
      console.log("Error config:", error.config);
    } finally {
      setLoading(false);
    }
  };

  const applyCoupon = async () => {
    try {
      setLoading(true);
      // console.log("Coupon Code just before post request :", couponCode);
      const couponcode = couponCode;
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/cart/apply-coupon`,
        { couponcode },
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log("Coupon Code applied :", couponCode);
      console.log("Apply coupon post request function response", response.data)
      const { message, cart: updatedCart } = response.data;

      // console.log("TotalAmount withoust gst  : ", totalAmount);
      setCart(updatedCart.items.length);
      const updatedCartTotal = updatedCart.cartTotal;
      console.log("updatedCartTotal", updatedCartTotal);
      console.log("you saved :", updatedCart.Discount);
      const finalDiscountedTotal = updatedCartTotal + gst;
      console.log("Final CartTotal with gst", finalDiscountedTotal);
      setTotalAmount(updatedCart.cartTotal);
      setGst(updatedCart.GST);
      setPayableAmount(updatedCart.cartTotalaftergst)

      setIsCouponApplied(true);
      setCartTotal(finalDiscountedTotal.toFixed(2));
      setDiscount(updatedCart.Discount)
      setShowCartTotal(true);
      setApplyVisible(false);
      setRemoveVisible(true);
      toast.success(message);
    } catch (error) {
      console.log("Failed to apply coupon:", error);
      setCouponCode("");
      setIsCouponApplied(false);
      setApplyVisible(true);
      setRemoveVisible(false);
      // console.log("apply coupon unsuccessful hone ke baad setCouponCode state :", couponCode);
      if (error.response && error.response.status === 404) {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      } else if (error.response && error.response.status === 400) {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      }
    } finally {
      setLoading(false);
    }
  };

  //remove coupon==
  const removeCoupon = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/cart/remove-coupon`,
        {
          data: { couponCode }, // Send the coupon code in the request body
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      const { message, cart: updatedCart } = response.data;
      setTotalAmount(updatedCart.cartTotal);
      setGst(updatedCart.GST);
      setPayableAmount(updatedCart.cartTotalaftergst)
      setIsCouponApplied(false);
      setCouponCode("");
      setCart(updatedCart.items.length);
      setShowCartTotal(false);
      setCartTotal(updatedCart.cartTotal);
      setApplyVisible(true);
      setRemoveVisible(false);
      toast.success(message);
    } catch (error) {
      toast.error("Failed to remove Coupon");
      console.error("Error removing coupon:", error);
    } finally {
      setLoading(false);
    }
  };

  //  Order Placing api
  const OrderPlace = async () => {
    setLoading(true);

    try {
      const data = {
        cartId: cartId,
        vendorId: "65d098cd4beec65a75da2cf1",
        modeOfPayment: "Cheque",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/order/create`,
        data,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success == true) {
        toast.success(response.data.message);
        getCartItems();
        navigate("/");
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Order not Placed! ");
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  const continueShopping = () => {
    navigate("/");
  };

  const toTitleCase = (str) =>
    str.replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    );


  const handleAddtoCart = async (serviceType, serviceId, planId, planPrice, durationMonths) => {
    let cartId = null;
    if (localStorage.getItem("cartId") && !token) {
      cartId = JSON.parse(localStorage.getItem("cartId"));
    }
    setLoading(true);
    try {
      // Adding Items to the Cart
      const data = {
        serviceType,
        service: {
          id: serviceId,
          name: serviceType,
        },
        plans: [
          {
            id: planId,
            price: planPrice,
            durationMonths
          },
        ],
        cartId
      };
      const addToCartRes = await axios.post(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/cart/${token ? "addtocart" : "add-to-cart"
        }`,
        data,
        { withCredentials: true, headers: { Authorization: `Bearer ${token}` } }
      );

      if (!addToCartRes.data) {
        throw new Error("Error occurred while adding to Cart");
      } else {
        setcartItem(addToCartRes?.data?.cart?.items);
        setcartId(addToCartRes?.data?.cart?._id);
        setCart(addToCartRes?.data?.items?.cart?.length);
        setTotalAmount(addToCartRes?.data?.cart?.cartTotal);
        setGst(addToCartRes?.data?.cart?.GST);
        setPayableAmount(addToCartRes?.data?.cart?.cartTotalaftergst);
        if (addToCartRes?.data?.cart.appliedCoupon) {
          setIsCouponApplied(true);
          setCouponCode(addToCartRes?.data?.cart.appliedCoupon.couponcode)
          setCartTotal(addToCartRes?.data?.cart.cartTotalaftergst.toFixed(2));
          setDiscount(addToCartRes?.data?.cart.Discount)
          setShowCartTotal(true);
          setApplyVisible(false);
          setRemoveVisible(true);
        } else {
          setIsCouponApplied(false);
          setCouponCode("");
          setDiscount(null);
          setShowCartTotal(false);
          setCartTotal(addToCartRes?.data?.cart.cartTotalaftergst.toFixed(2));
          setApplyVisible(true);
          setRemoveVisible(false);
        }
        toast.success("Plan added");
      }
    } catch (error) {
      console.error("Error occurred during API requests:", error);
      toast.error(error.message || "Error occurred during API requests");
    }
    setLoading(false);
  }

  return (
    <div className="w-[85%] md:w-[90%] lg:w-[80%] xl:w-[70%] max-w-[1500px] mx-auto flex flex-col gap-y-5 my-3" style={{ fontFamily: "inter" }}>
      <h1 className="text-left text-xl font-bold">My Cart</h1>
      {cartItem?.length === 0 ? (
        <div className="mt-4 flex flex-col">
          <FaCartPlus className="mt-4 mx-auto text-4xl text-[#2d2b2bc7]" />
          <h1 className="text-3xl text-[#323232c7] text-center font-semibold mt-3">Oops! Your Cart is Empty</h1>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row justify-between gap-x-5 gap-y-5">
          {/* left part */}
          <div className="md:w-[57%] xl:w-[70%]">
            {/* cart item */}
            <div className="flex flex-col gap-y-5">
              {cartItem && cartItem?.length > 0 && cartItem.map((item) => (
                <div key={item._id} className="flex justify-between shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-sm py-4 px-4">
                  <div className="flex flex-col gap-y-3 max-sm:w-[75%] w-full">
                    <div className="flex justify-between">
                      <h2 className="text-left font-medium text-lg pr-3">{item.service.name || item.serviceType}</h2>
                      {item?.plans?.length > 1 && (
                        <b> ₹&nbsp;{item?.plans?.reduce((acc, curr) => acc + curr.price, 0)} </b>
                      )}
                    </div>
                    {item?.plans?.length === 1 && (
                      <>
                        <span className="font-normal text-left">{item?.plans[0]?.planName}</span>
                        {item.plans[0]?.durationMonths && (
                          <p className="text-left text-sm">
                            {item?.plans[0]?.durationMonths} Months {item?.plans[0]?.coverLetterIncluded && "Cover letter included"}
                          </p>
                        )}
                      </>
                    )}
                    {item?.plans?.length > 1 && (
                      <div>
                        <p className="text-left text-sm mb-3">Include {item.plans.length} plans</p>
                        {item?.plans?.map((plan) => (
                          <div key={plan._id} className="flex lg:space-x-20 justify-between pt-[7px] w-full">
                            <span className="font-normal text-left w-[20vw]">{plan.planName}</span>
                            <div className="items-end text-right">
                              <span className="font-normal"> ₹{plan.price} </span>
                              <button className="text-sm text-red-500 ml-4" onClick={DeleteCartItem(plan.id)}>Delete</button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="flex justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-1"></div>
                  </div>
                  <div className="flex flex-col gap-y-6 ">
                    {item?.plans?.length === 1 && (
                      <>
                        <b> ₹&nbsp;{item.plans[0].price} </b>
                        <button className="text-sm text-red-500" onClick={DeleteCartItem(item.plans[0].id)}>Delete</button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* Frequently Bought Together */}
            <div className="mt-4 flex flex-col gap-2">
              <h1 className="text-base font-medium text-left">Frequently Bought Together</h1>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-y-5">
                {allService?.length ? (
                  allService.map((service) => (
                    <div key={service._id} className="border-[1.2px] border-gray-400/90 flex flex-col gap-2 md:w-[95%] mx-auto hover:shadow-md transition-shadow duration-200 cursor-pointer" onClick={() => (window.location.href = toTitleCase(service.serviceName || service.jobServiceName).split(" ").join("-"))}>
                      <div className="w-full h-full">
                        <img src={service.image} alt="" className="mx-auto h-[150px]" loading="lazy" />
                      </div>
                      <div className="flex flex-col gap-1 h-full p-2">
                        <h1 className="text-sm font-medium text-left ">{service.serviceName || service.jobServiceName}</h1>
                        {/* <p className="line-clamp-2 text-left text-sm text-gray-600 mt-1">{service.description}</p> */}
                        <div className="mt-auto flex justify-between items-center">
                          <p className="text-left text-[13px]">Price : <span className="text-[12.5px] font-medium">₹{service.price || service?.plans[0]?.price}</span></p>
                          <button style={{ backgroundColor: 'rgb(19,124,79)' }} className="text-[12px] px-1 rounded-md text-white" onClick={(e) => {
                            e.stopPropagation();
                            const serviceName = (service?.serviceName || service?.jobServiceName);
                            const serviceId = service?._id;
                            const planId = (service?.plans && service?.plans[0]?._id) || service?._id;
                            const price = (service?.plans && service?.plans[0]?.price) || service?.price;
                            const durationMonths = (service?.plans && service?.plans[0].durationMonths);
                            handleAddtoCart(serviceName, serviceId, planId, price, durationMonths)
                          }}>
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-left">Loading ...</p>
                )}
              </div>
            </div>
          </div>
          {/* payment box - right part*/}
          <div className="md:w-[40%] xl:w-[30%] flex flex-col gap-y-3">
            <div className="flex flex-col justify-between shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-sm py-4 px-4 gap-y-8">
              <div className="flex flex-col gap-y-3">
                <>
                  <b className="text-lg text-left">Price Details</b>
                  {isCouponApplied && discount ? (
                    <div className="flex flex-col gap-y-3">
                      <div className="flex justify-between">
                        <p className="text-sm font-medium">Sub Total</p>
                        <p className="text-gray-500 font-medium text-sm">₹{totalAmount?.toFixed(0)}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-sm font-medium">Discount</p>
                        <p className="text-green-600 text-sm font-medium">-₹{discount.toFixed(0)}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-sm font-medium">Taxes and Charges</p>
                        <p className="text-sm font-medium text-gray-500">₹{gst?.toFixed(0)}</p>
                      </div>

                    </div>
                  ) : (
                    <div className="flex flex-col gap-y-3">
                      <div className="flex justify-between">
                        <p className="text-sm font-medium">Sub Total</p>
                        <p className="text-gray-500 font-medium text-sm">₹{totalAmount?.toFixed(0)}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-sm font-medium">Taxes and Charges</p>
                        <p className="text-sm font-medium text-gray-500">₹{gst?.toFixed(0)}</p>
                      </div>
                    </div>
                  )}
                </>
              </div>
              <div className="flex flex-col gap-y-8 border-t-2 pt-3">
                {/* {showcartTotal && cartTotal !== null && cartTotal !== 0 ? (
                  <>
                    <div className="flex justify-between font-bold">
                      <p className="text-left text-sm font-medium">Grand Total</p>
                      <p className="text-sm font-medium">₹{payableAmount?.toFixed(0)}</p>
                    </div>
                  </>
                ) : ( */}
                <div className="flex justify-between font-semibold text-[15px]">
                  <p className="text-left max-w-[55%] text-sm font-medium">Grand Total</p>
                  <p className="text-right text-sm font-medium">₹{payableAmount?.toFixed(0)}</p>
                </div>
                {/* )} */}
                <button className="bg-orange-500 text-white font-semibold px-3 py-2 rounded-lg flex justify-center items-center" onClick={() => {
                  if (token) {
                    navigate("/check-out", { state: { cartItem, totalAmount, gst, payableAmount, cartId, user } });
                  } else {
                    localStorage.setItem("intendedDestination", "/cart");
                    openLoginModal(); // Open login modal if user not authenticated
                  }
                }}>
                  CONTINUE
                </button>

                {showLoginModal && <LoginModal closeModal={closeLoginModal} />}
              </div>
            </div>
            <div className="flex justify-between items-center gap-x-2 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-sm py-3 px-3 text-sm" onClick={() => {
              if (!token) { toast.error("Please login to apply offers"); return; }
            }
            }>
              <div className="flex flex-col gap-y-1">
                <input ref={inputRef}
                  type="text"
                  value={couponCode}
                  onChange={handleInputChange}
                  onClick={() => {
                    if (token) { setShowOptions(true); }
                    else {
                      openLoginModal();
                      return;
                    }
                  }}
                  placeholder="Enter Your Coupon Code" className="outline-none pb-1 border-b-2 text-gray-700 grow" />
                <ul className="flex flex-col justify-center items-stretch gap-y-2 text-gray-500 italic text-xs mt-1">
                  {showOptions && options && options?.filter((coupon) => coupon.limit > 0).map((coupon) => (

                    <li key={coupon.couponcode} className="coupon rounded-md p-2 grid gap-1 border-1 border-orange-500 cursor-pointer" onClick={() => handleOptionSelect(coupon.couponcode)}>
                      <div className="row1 flex font-bold text-black justify-between">
                        <p>{coupon.name}</p>
                        <p>{coupon.couponcode}</p>
                      </div>
                      <div className="row2 flex font-semibold justify-between">
                        <div className="grid text-black">
                          <p>Discount:</p>
                          <p> {coupon.discountpercentage}%</p>
                        </div>
                        <div className="grid text-black">
                          <p>Expiry Date:</p>
                          <p> {coupon.expiryDate}</p>
                        </div>
                      </div>
                    </li>

                  ))}
                </ul>
              </div>
              <button className={`text-orange-500 border-2 border-orange-500 px-3 py-1 rounded-md self-start ${applyVisible ? "" : "hidden"}`} onClick={() => { if (token) { applyCoupon(); } else { toast.error("Please login to apply offers"); return; } }} disabled={!couponCode}>
                Apply
              </button>
              <button className={`text-orange-500 border-2 border-orange-500 px-3 py-1 rounded-md self-start ${removeVisible ? "" : "hidden"}`} onClick={removeCoupon}>
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
      <button onClick={continueShopping} className="mt-5 self-center md:self-start border-2 border-orange-500 text-orange-500 text-sm font-semibold px-3 py-2 flex justify-center items-center">
        CONTINUE SHOPPING
      </button>
      <div className="h-40" />
    </div>
  );
};

export default Cart;
