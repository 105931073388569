import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import TermsAndConditionsPopup from "./PaymentScreen/ScreeningServices/TermsAndConditionsPopup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import Secure from "../images/secure.png";

const CheckoutPage = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [tnc, setTnc] = useState(false);

  const handleAgreeToTerms = (agree) => {
    setShowTermsPopup(false);
    if (agree) {
      setTnc(true);
    }
  };

  const handleCheckboxChange = () => {
    if (!tnc) {
      setShowTermsPopup(true);
    } else {
      setTnc(false);
    }
  };

  const handleSubmit = () => {
    const paymentLink = urlParams.get('paymentLink');
    window.open(paymentLink, '_self', 'noopener,noreferrer');
  };

  return (
    <div style={{ fontFamily: "poppins" }}>
      <div className='bg-indigo-900'>
        <p className='text-white p-6 text-2xl font-semibold text-center'>Check Out</p>
      </div>
      <div className='container mx-auto flex justify-center mt-10 mb-10'>
        <div className='flex flex-col lg:flex-row lg:w-3/4 border border-gray-300 rounded-lg overflow-hidden'>
          <div className='bg-gray-100 lg:w-1/4 p-4 flex flex-col justify-center items-center'>
            <img src={Secure} alt="image" className='w-2/3 mb-4' />
            <p className="text-center">All major credit and debit cards accepted</p>
          </div>
          <div className='lg:w-3/4 p-6'>
            <p className="text-lg font-semibold mb-2">Invoice Details:</p>
            <div className="flex flex-col lg:flex-row justify-between mb-4">
            <div className="flex flex-col">
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              <p>{urlParams.get('address')}</p>
            </div>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              <p>{urlParams.get('email')}</p>
            </div>
            <div className="flex items-center">
             <FontAwesomeIcon icon={faPhone} className="mr-2" />
             <p>{urlParams.get('phone')}</p>
            </div>
          </div>

              <div>
                <p><strong>Amount to be paid</strong> </p>
                <p>{urlParams.get('amount')}</p>
              </div>
            </div>
            <div className="mb-4 ml-7 flex justify-start items-center">
              <input
                type="checkbox"
                id="terms-checkbox"
                checked={tnc}
                onChange={handleCheckboxChange}
                className="mr-2 cursor-pointer"
              />
              <label className="text-xs" htmlFor="terms-checkbox">
                I HAVE READ AND AGREE TO THE WEBSITE{" "}
                <a href="#" className="text-blue-500">
                  TERMS AND CONDITIONS
                </a>
                <span className="text-red-500">*</span>
              </label>
            </div>
            {showTermsPopup && (
              <TermsAndConditionsPopup
                onClose={() => setShowTermsPopup(false)}
                onAgree={handleAgreeToTerms}
              />
            )}
            <button
              className="bg-[#324c81] text-[13px] ml-7 text-white px-3 py-2 "
              disabled={!tnc}
              onClick={handleSubmit}
            >
              PLACE ORDER
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
