import React from "react";
import img1 from "../../src/images/image 243.png";
import img2 from "../../src/images/image 247.png";
import img3 from "../../src/images/image 246.png";
import img4 from "../../src/images/image 248.png";
import img5 from "../../src/images/image 249.png";
import img6 from "../../src/images/image 250.png";
import "../../src/css/partnerlogoscoller.css";

export default function PartnerLogoScroller() {
  

  return (
    <div className="">
        <h1 className=" mt-3 mb-4 underline font-bold">Partner Sites</h1>

      <div className="partnersimg ml-96">
        <div className="scroller">
          <img src={img1} alt="Pic1" className="partnerimg" />
          <img src={img2} alt="Pic2" className="partnerimg" />
          <img src={img3} alt="Pic3" className="partnerimg " />
          <img src={img4} alt="Pic4" className="partnerimg" />
          <img src={img5} alt="Pic5" className="partnerimg" />
          <img src={img6} alt="Pic6" className="partnerimg" />
          {/* Duplicate the images for continuous scroll effect */}
          <img src={img1} alt="Pic1" className="partnerimg" />
          <img src={img2} alt="Pic2" className="partnerimg" />
          <img src={img3} alt="Pic3" className="partnerimg" />
          <img src={img4} alt="Pic4" className="partnerimg" />
          <img src={img5} alt="Pic5" className="partnerimg" />
          <img src={img6} alt="Pic6" className="partnerimg" />
          {/* duplicates */}
          <img src={img1} alt="Pic1" className="partnerimg" />
          <img src={img2} alt="Pic2" className="partnerimg" />
          <img src={img3} alt="Pic3" className="partnerimg" />
          <img src={img4} alt="Pic4" className="partnerimg" />
          <img src={img5} alt="Pic5" className="partnerimg" />
          <img src={img6} alt="Pic6" className="partnerimg" />
        </div>
      </div>
    </div>
  );
}
