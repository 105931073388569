import React, { useState } from "react";

function Complaint() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    feedback: "",
    options: {
      defectiveProduct: false,
      poorWorkmanship: false,
      damagedDuringTransit: false,
      incorrectSpecifications: false,
      other: false,
      otherDetails: "",
    },
    serviceDelays: {
      delayedDelivery: false,
      missedDeadlines: false,
      appointmentDelays: false,
      longWaitTimes: false,
      otherServiceDelays: false,
      otherServiceDelaysDetails: "",
    },
    billingDisputes: {
      overcharged: false,
      unauthorizedCharges: false,
      incorrectBillingInfo: false,
      doubleBilling: false,
      otherBillingDisputes: false,
      otherBillingDisputesDetails: "",
    },
    customerService: {
      rudeUnprofessionalBehavior: false,
      unhelpfulStaff: false,
      poorCommunication: false,
      lackOfResponse: false,
      otherCustomerServiceIssues: false,
      otherCustomerServiceIssuesDetails: "",
    },
    websiteErrors: {
      technicalGlitches: false,
      difficultyNavigating: false,
      websiteCrashes: false,
      paymentErrors: false,
      otherWebsiteErrors: false,
      otherWebsiteErrorsDetails: "",
    },
    refundIssues: {
      difficultyObtainingRefunds: false,
      delayedRefundProcessing: false,
      refundDenied: false,
      partialRefund: false,
      otherRefundIssues: false,
      otherRefundIssuesDetails: "",
    },
    communicationProblems: {
      lackOfOrderUpdates: false,
      noOrderConfirmation: false,
      unableToReachSupport: false,
      poorCommunicationResolution: false,
      otherCommunicationProblems: false,
      otherCommunicationProblemsDetails: "",
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        options: {
          ...formData.options,
          [name]: checked,
        },
      });
    } else if (name === "otherDetails") {
      setFormData({
        ...formData,
        options: {
          ...formData.options,
          otherDetails: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("phoneNumber", formData.phoneNumber);
    formDataToSend.append("feedback", formData.feedback);
    
    // Append options data
    Object.keys(formData.options).forEach((key) => {
      formDataToSend.append(`options[${key}]`, formData.options[key]);
    });

    // Append serviceDelays data
    Object.keys(formData.serviceDelays).forEach((key) => {
      formDataToSend.append(`serviceDelays[${key}]`, formData.serviceDelays[key]);
    });

    // Append billingDisputes data
    Object.keys(formData.billingDisputes).forEach((key) => {
      formDataToSend.append(`billingDisputes[${key}]`, formData.billingDisputes[key]);
    });

    // Append customerService data
    Object.keys(formData.customerService).forEach((key) => {
      formDataToSend.append(`customerService[${key}]`, formData.customerService[key]);
    });

    // Append websiteErrors data
    Object.keys(formData.websiteErrors).forEach((key) => {
      formDataToSend.append(`websiteErrors[${key}]`, formData.websiteErrors[key]);
    });

    // Append refundIssues data
    Object.keys(formData.refundIssues).forEach((key) => {
      formDataToSend.append(`refundIssues[${key}]`, formData.refundIssues[key]);
    });

    // Append communicationProblems data
    Object.keys(formData.communicationProblems).forEach((key) => {
      formDataToSend.append(`communicationProblems[${key}]`, formData.communicationProblems[key]);
    });

    // Append file
    formDataToSend.append("file", file);

    // Perform API call
    try {
      const response = await fetch("YOUR_API_ENDPOINT", {
        method: "POST",
        body: formDataToSend,
      });
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        // Handle error
        console.error("Error while submitting form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <form onSubmit={handleSubmit}>
        <div className="w-[80%] mx-auto">
          <h1 className="font-semibold text-2xl md:text-[20px] leading-[50px] md:leading-[70px] p-2 bg-[#F0AF73] text-left ">
            Content Concern Form - Addressing Complaints and Consumer Grievances
          </h1>
        </div>
        <div className="flex flex-col  text-left mx-auto gap-4 w-[80%]">
          <div className="flex mt-11">
            <p className="text-red-700 mr-3 font-bold">*</p>
            <div className="flex flex-col ">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] sm:w-[460px] sm:h-[30px] pl-4  outline-2 border-2 border-black rounded-sm"
                required
              />
            </div>
          </div>
          <div className="flex ">
            <p className="text-red-700 mr-3 font-bold">*</p>
            <div className="flex flex-col">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] sm:w-[460px] sm:h-[30px] pl-4  outline-2 border-2 border-black rounded-sm"
                required
              />
            </div>
          </div>
          <div className="flex ">
            <p className="text-red-700 mr-3 font-bold">*</p>
            <div className="flex flex-col">
              <label>Phone Number</label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] sm:w-[460px] sm:h-[30px] pl-4 outline-2 border-2 border-black rounded-sm"
                required
              />
            </div>
          </div>
          <br />
          <div>
            <label className="block mb-2 leading-10 text-[15px] xl:w-[70%]">
              {" "}
              We value your feedback and are committed to providing excellent
              customer service. If you have
              encountered any issues or have feedback to share, please
              use the form below to submit your
               complaint. Our team will review your submission and work
              diligently to address your concerns.
            </label>
          </div>
          <br />
          <div>
            <div className="mb-2 font-bold leading-6">Product quality</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="defectiveProduct"
                checked={formData.options.defectiveProduct}
                onChange={handleChange}
                className="mr-2"
              />
              Defective product received, not meeting quality standards.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="poorWorkmanship"
                checked={formData.options.poorWorkmanship}
                onChange={handleChange}
                className="mr-2"
              />
              Poor workmanship observed, affecting product functionality.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="damagedDuringTransit"
                checked={formData.options.damagedDuringTransit}
                onChange={handleChange}
                className="mr-2"
              />
              Goods arrived damaged during transit, impacting usability.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="incorrectSpecifications"
                checked={formData.options.incorrectSpecifications}
                onChange={handleChange}
                className="mr-2"
              />
              Product specifications were incorrect, leading to dissatisfaction.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="other"
                checked={formData.options.other}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="otherDetails"
                value={formData.options.otherDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black xl:ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">serviceDelays</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="delayedDelivery"
                checked={formData.options.delayedDelivery}
                onChange={handleChange}
                className="mr-2"
              />
              Experience delayed delivery, extending beyond promised timeframe.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="missedDeadlines"
                checked={formData.options.missedDeadlines}
                onChange={handleChange}
                className="mr-2"
              />
              Missed deadlines for service completion, causing inconvenience.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="appointmentDelays"
                checked={formData.options.appointmentDelays}
                onChange={handleChange}
                className="mr-2"
              />
              Appointment delays resulting in wasted time and inconvenience.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="longWaitTimes"
                checked={formData.options.longWaitTimes}
                onChange={handleChange}
                className="mr-2"
              />
              Encounter long wait times for assistance or support services.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="otherServiceDelays"
                checked={formData.options.otherServiceDelays}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="otherServiceDelaysDetails"
                value={formData.options.otherServiceDelaysDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Billing Disputes</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="overcharged"
                checked={formData.options.overcharged}
                onChange={handleChange}
                className="mr-2"
              />
              Overcharged for products or services rendered.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="unauthorizedCharges"
                checked={formData.options.unauthorizedCharges}
                onChange={handleChange}
                className="mr-2"
              />
              Unauthorized charges appeared on the bill, leading to confusion.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="incorrectBillingInfo"
                checked={formData.options.incorrectBillingInfo}
                onChange={handleChange}
                className="mr-2"
              />
              Incorrect billing information provided, causing discrepancies.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="doubleBilling"
                checked={formData.options.doubleBilling}
                onChange={handleChange}
                className="mr-2"
              />
              Double billing occurred, resulting in duplicate charges.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="otherBillingDisputes"
                checked={formData.options.otherBillingDisputes}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="otherBillingDisputesDetails"
                value={formData.options.otherBillingDisputesDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Customer Service</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="rudeUnprofessionalBehavior"
                checked={formData.options.rudeUnprofessionalBehavior}
                onChange={handleChange}
                className="mr-2"
              />
              Experienced rude or unprofessional behavior from staff.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="unhelpfulStaff"
                checked={formData.options.unhelpfulStaff}
                onChange={handleChange}
                className="mr-2"
              />
              Staff members were unhelpful or lacked proper assistance.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="poorCommunication"
                checked={formData.options.poorCommunication}
                onChange={handleChange}
                className="mr-2"
              />
              Poor communication from customer service representatives.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="lackOfResponse"
                checked={formData.options.lackOfResponse}
                onChange={handleChange}
                className="mr-2"
              />
              Lack of response to inquiries or complaints submitted.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="otherCustomerServiceIssues"
                checked={formData.options.otherCustomerServiceIssues}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="otherCustomerServiceIssuesDetails:"
                value={formData.options.otherCustomerServiceIssuesDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Website Errors</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="technicalGlitches"
                checked={formData.options.technicalGlitches}
                onChange={handleChange}
                className="mr-2"
              />
              Encountered technical glitches or errors while using the website.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="difficultyNavigating"
                checked={formData.options.difficultyNavigating}
                onChange={handleChange}
                className="mr-2"
              />
              Difficulty navigating the website or finding desired information.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="websiteCrashes"
                checked={formData.options.websiteCrashes}
                onChange={handleChange}
                className="mr-2"
              />
              Website crashed or experienced downtime during use.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="paymentErrors"
                checked={formData.options.paymentErrors}
                onChange={handleChange}
                className="mr-2"
              />
              Payment processing errors occurred during checkout process.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="otherWebsiteErrors"
                checked={formData.options.otherWebsiteErrors}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="otherWebsiteErrorsDetails"
                value={formData.options.otherWebsiteErrorsDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Refund Issues</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="difficultyObtainingRefunds"
                checked={formData.options.difficultyObtainingRefunds}
                onChange={handleChange}
                className="mr-2"
              />
              Difficulty in obtaining refunds for returned products or services.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="delayedRefundProcessing"
                checked={formData.options.delayedRefundProcessing}
                onChange={handleChange}
                className="mr-2"
              />
              Delayed processing of refund requests, leading to frustration.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="refundDenied"
                checked={formData.options.refundDenied}
                onChange={handleChange}
                className="mr-2"
              />
              Refund was denied without valid reason or explanation provided.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="partialRefund"
                checked={formData.options.partialRefund}
                onChange={handleChange}
                className="mr-2"
              />
              Received partial refund amount or refund processed incorrectly.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="otherRefundIssues"
                checked={formData.options.otherRefundIssues}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="otherRefundIssuesDetails"
                value={formData.options.otherRefundIssuesDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div className="mb-6">
            <div className="mb-2 font-bold">Communication Problems</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="lackOfOrderUpdates"
                checked={formData.options.lackOfOrderUpdates}
                onChange={handleChange}
                className="mr-2"
              />
              Lack of communication regarding order status or updates.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="noOrderConfirmation"
                checked={formData.options.noOrderConfirmation}
                onChange={handleChange}
                className="mr-2"
              />
              Did not receive order confirmation or shipping notifications.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="unableToReachSupport"
                checked={formData.options.unableToReachSupport}
                onChange={handleChange}
                className="mr-2"
              />
              Unable to reach customer service or support team for assistance.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="poorCommunicationResolution"
                checked={formData.options.poorCommunicationResolution}
                onChange={handleChange}
                className="mr-2"
              />
              Poor communication regarding resolution of complaints or issues.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="otherCommunicationProblems"
                checked={formData.options.otherCommunicationProblems}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="otherCommunicationProblemsDetails"
                value={formData.options.otherCommunicationProblemsDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div className="mb-2 font-bold">
            Please upload any supported document If you have any related to your
            concerns or complaint
          </div>
          <div className=" flex flex-col  gap-1">
            <input
              type="file"
              id="fileInput"
              accept="image/*,application/pdf bg-[#D9D9D9] "
              onChange={handleFileChange}
            />
            <br />
            <div className="mb-2 text-xs">
              (Please upload a GIF, PNG, JPG, PDF or JPEG file only and ensure
              that the file is currently not in use.
              
            </div>
            <p className="text-xs"> [Maximum File Size Limit 2 MB])</p>
          </div>
          <div className="mb-10">
          <button
            type="submit"
            className="bg-[#F58634] hover:bg-[#b16834] text-white font-bold py-2 px-4 rounded mt-4 w-[150px]     "
          >
            Submit
          </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Complaint;
