import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import contextAuth from "../ContextAPI/ContextAuth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { loading, setLoading } = useContext(contextAuth);

    const sendOtp = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            toast.error("Enter a valid email.");
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_PRO_URL}/api/user/sendOTPForPasswordReset`,
                { email }
            );
            if (response.data.status !== "success") {
                throw new Error("ERROR OCCURED IN SENDING OTP......");
            }
            setEmailSent(true);
            toast.success(response.data?.message);
        } catch (error) {
            console.log("ERROR OCCURED IN SENDING OTP......", error);
            toast.error(error.response.data?.error);
        }
        setLoading(false);
    };

    const handleSubmit = async () => {
        setLoading(true);
        console.log(email, otp, newPassword)

        try {
            const otpInteger = parseInt(otp);
            const response = await axios.patch(
                `${process.env.REACT_APP_SERVER_PRO_URL}/api/user/verifyOTPAndSetNewPassword`,
                { email, otp: otpInteger, newPassword }
            );
            if (response.data.status !== "success") {
                throw new Error("ERROR OCCURED IN RESETTING PASSWORD......");
            }
            toast.success(response.data?.message);
            navigate("/login");
        } catch (error) {
            console.log("ERROR OCCURED IN SENDING OTP......", error);
            toast.error("Unable to Reset Password.");
        }
        setLoading(false);
    };

    return (
        <div style={{ fontFamily: "inter" }}>
            <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] m-5 max-md:px-3 md:px-5 py-10 w-[90%] md:w-[70%] lg:w-[50%] max-w-[1000px] mx-auto">
                <div className="flex flex-col gap-8 max-w-[90%] sm:w-[50%] sm:min-w-[350px] mx-auto">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold text-gray-500">
                            Forgot Password
                        </h2>
                        <Link to="/login">
                            <RxCross2 className="text-xl font-bold text-gray-500 hover:scale-125 hover:text-orange-400 transition-all duration-150" />
                        </Link>
                    </div>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-2">
                            <label
                                htmlFor="email"
                                className="text-left text-xs font-semibold text-gray-500"
                            >
                                Email Id
                            </label>
                            <div className="flex gap-2">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={emailSent}
                                    placeholder="Enter your email address here"
                                    className="outline-none border rounded-md p-2 text-sm font-normal text-gray-600 grow"
                                    required
                                />
                                <button
                                    type="submit"
                                    disabled={loading || !email}
                                    className="bg-orange-400 text-white text-xs sm:text-sm font-normal px-2 py-1 rounded-lg hover:bg-orange-500 hover:shadow transition-all duration-150"
                                    onClick={sendOtp}
                                >
                                    Send OTP
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <label
                                htmlFor="otp"
                                className="text-left text-xs font-semibold text-gray-500"
                            >
                                Enter OTP
                            </label>
                            <input
                                type="text"
                                name="otp"
                                id="otp"
                                value={otp}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                }}
                                maxLength={6}
                                disabled={!emailSent}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                className="outline-none border rounded-md p-2 text-sm font-normal text-gray-600"
                                required
                            />
                        </div>
                        {emailSent && (
                            <div className="flex flex-col gap-1 relative">
                                <label
                                    htmlFor="newPassword"
                                    className="text-left text-xs font-semibold text-gray-500"
                                >
                                    Enter New Password
                                </label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="newPassword"
                                    id="newPassword"
                                    value={newPassword}
                                    disabled={!emailSent}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter new password"
                                    className="outline-none border rounded-md p-2 text-sm font-normal text-gray-600"
                                    required
                                />
                                <span
                                    onClick={() => setShowPassword((prev) => !prev)}
                                    className="absolute right-2 top-7 cursor-pointer"
                                >
                                    {showPassword ? (
                                        <AiOutlineEyeInvisible fontSize={20} fill="#AFB2BF" />
                                    ) : (
                                        <AiOutlineEye fontSize={20} fill="#AFB2BF" />
                                    )}
                                </span>
                            </div>
                        )}
                        <button
                            className="bg-orange-400 text-white text-sm font-normal px-4 py-2 rounded-lg hover:bg-orange-500 hover:shadow transition-all duration-150 mt-2 w-fit mx-auto"
                            disabled={!emailSent}
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
